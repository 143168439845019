import commonHelper from '@/app/utility/common.helper.utility';
import addEditBillingPlan from './addEditBillingPlan';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';
export default {
  name: 'BillingPlan',
  components: {
    addEditBillingPlan,
    ModelSelect,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getBillingPlanList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBillingPlanList();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      loader: false,
      setTimeVsetCode: null,
      vsetCode: null,
      payload: null,
      showBillingCycleModal: false,
      billingCycleHdrId: null,
      showValueSetModal: false,
      billingPlanData: [],
      billingPlanDataFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'billing_plan_name',
          label: 'Plan Name'
        },
        {
          key: 'billing_plan_description'
        },
        {
          key: 'billing_cycle_name',
          label: 'Billing Cycle'
        },
        {
          key: 'status_meaning',
          label: 'Plan Status'
        },
        {
          key: 'plan_unit_type_meaning',
          label: 'Plan Type'
        },
        {
          key: 'bill_date_ref_date',
          label: 'Bill Ref Date'
        },
        {
          key: 'bill_date_ref_period'
        },
        {
          key: 'bill_gen_ref_date',
          label: 'Bill Gen Ref Date'
        },
        {
          key: 'bill_gen_date_ref_period'
        },
        {
          key: 'billing_start_date',
          label: 'Start Date'
        },
        {
          key: 'billing_end_date',
          label: 'End Date'
        }
      ],
      searchFrom: {
        planName: '',
        description: '',
        billingCycle: {
          id: null,
          text: null
        },
        planStatus: {
          value: null,
          text: null
        },
        planType: {
          value: null,
          text: null
        },
        startDate: null,
        endDate: null,
        is_post_billing: false
      }
    };
  },
  mounted() {
    if (this.$store.state.masterData.filterData) {
      this.searchFrom = this.$store.state.masterData.filterData;
    }
    this.getBillingPlanList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.billingCycleHdrId = 0;
          this.$router.push('billing-plan-detail/' + this.billingCycleHdrId);
        }
        if (actionName === 'download' && !this.showBillingCycleModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getBillingPlanList',
            'billing-plan',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'billingPlanDetails') {
      this.$store.commit('masterData/setFilterData', null);
    }
    next();
  },
  methods: {
    getBillingPlanList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        billing_plan_name: this.searchFrom.planName,
        billing_plan_description: this.searchFrom.description,
        billing_cycle_name: this.searchFrom.billingCycle.id,
        plan_status: this.searchFrom.planStatus.value,
        plan_unit_type_vset: this.searchFrom.planType.value,
        billing_start_date: this.searchFrom.startDate
          ? format(new Date(this.searchFrom.startDate), 'dd-MMM-yyyy')
          : null,
        billing_end_date: this.searchFrom.endDate
          ? format(new Date(this.searchFrom.endDate), 'dd-MMM-yyyy')
          : null,
        is_post_billing: this.searchFrom.is_post_billing
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getBillingPlanList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.billingPlanData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === 'FMS_PENALTY_TERM') {
        this.vsetCode = 'FMS_PENALTY_TERM';
      } else {
        this.vsetCode = vsetCode;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName:
            vsetCode === 'FMS_PENALTY_TERM' ? 'FMS_PAYMENT_TERM' : vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.searchFrom.billingCycle = {
          text: item.billing_cycle_name,
          id: item.value_code
        };
      } else if (this.vsetCode === 'BILLING_PLAN_STATUS') {
        this.searchFrom.planStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.searchFrom.planType = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.searchFrom.billingCycle = { text: null, id: null };
      } else if (vsetCode === 'BILLING_PLAN_STATUS') {
        this.searchFrom.planStatus = { text: null, value: null };
      } else if (vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.searchFrom.planType = { text: null, value: null };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showhideBillingModal(flag) {
      this.showBillingCycleModal = flag;
    },
    rowSelected(item) {
      this.$store.commit('masterData/setFilterData', this.searchFrom);
      this.$store.commit('masterData/setPlanType', item.plan_unit_type_vset);
      this.$router.push('billing-plan-detail/' + item.billing_plan_hdr_id);
    },
    clearSearch() {
      this.$store.commit('masterData/setFilterData', null);
      this.searchFrom = {
        planName: '',
        description: '',
        billingCycle: {
          id: null,
          text: null
        },
        planStatus: {
          value: null,
          text: null
        },
        planType: {
          value: null,
          text: null
        },
        startDate: null,
        endDate: null,
        is_post_billing: false
      };
      this.billingPlanData = [];
      this.totalRows = null;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
